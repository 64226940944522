<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Team Leave Balance</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page"><router-link :to="{name: 'leave-approvals'}">Leave Approvals</router-link></li>
                <li class="breadcrumb-item active" aria-current="page" >Team Leave Balance</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <b-row>
                <b-col lg="4">
                    <b-form-group
                        label="Employee"
                        label-cols-sm="3"
                        label-align-sm="left"
                        label-size="sm"
                        class="mb-1"
                        label-for="employeefilter"
                        style="font-weight:bold;font-size:1rem !important;"
                    >
                        <EmployeeList :reporting_to="employeeReportingto" @emp_id="getEmpId" :employee_id="$route.query.emp_id"></EmployeeList>
                    </b-form-group>
                </b-col>
                <b-col lg="3" class="mb-1rem">
                    <div class="form-group row">
                        <label
                            class="col-lg-2 col-md-2 col-sm-12 col-form-label text-right"
                        >Year</label>
                        <div class="col-lg-10 col-md-10 col-sm-12">
                            <b-form-select
                                v-model="urlBody.year"
                                :options="years"
                                disabled-field="notEnabled"
                            ></b-form-select>
                        </div>
                    </div>
                </b-col>
                <b-col lg="1" sm="12" class="mb-1rem">
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <b-button variant="primary" @click="onFiltered">Filter</b-button>
                        </div>
                    </div>
                </b-col>
            </b-row>

            <b-table
                v-if="items"
                show-empty
                striped
                hover
                :items="items"
                :fields="fields"
                no-local-sorting
                @sort-changed="customSorting"
                responsive
            >
                
                <template v-slot:cell(update_at)="row">
                    {{ row.item.update_at }}
                </template>
                <template v-slot:cell(reason)="row">
                    {{row.item.reason}}
                </template>
            </b-table>
        </b-container>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
import EmployeeList from "@/components/EmployeeList.vue";
export default {
    components: {
        NotFound,
        EmployeeList
    },
    data() {
        return {
            urlBody: {
                query: "",
                sort_by: "",
                order: "",
                year: new Date().getFullYear(),
                emp_id: ''
            },
            employeeReportingto:1,
            api_error: "",
            sortBy: "id",
            sortDesc: false,
            fields: [
                { label: 'Leave Type', key: "type", sortable: true},
                { label: "No of Leaves", key: "no_of_leaves",sortable: false},
                { key: "balance",sortable: false},
                { key: "reason" ,tdClass: 'reasonClass'},
                { label: 'Updated At', key: "updated_at",sortable: false,
                    formatter: value => {
                        return this.dateformat(value);
                    }
                }
            ],
            items: null,
            access: true,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            infoModal: {
                id: "modal-sm",
                title: "",
                item_id: ""
            },
            message:"",
            remaining_leaves: '',
            years: [],
            total_years:2,
        };
    },
    mounted() {
        this.access = this.hasAnyPermission(["CAN_VIEW_ALL_LEAVE_BALANCE",'CAN_VIEW_TEAM_LEAVE_BALANCE']);
        if(this.$route.query.emp_id) {
            this.urlBody.emp_id = this.$route.query.emp_id;
        }
        this.getYears();
        this.employeeReportingto = (this.hasAnyPermission(['CAN_VIEW_ALL_LEAVE_BALANCE'])) ? 0 : 1;
        this.onPageLoad();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        getEmpId(id) {
            this.urlBody.emp_id = id;
        },

        getYears() {
            const currentYear = (new Date()).getFullYear();
            for (let index = 0; index < this.total_years; index++) {
                this.years.push({
                    value: currentYear-index, text:currentYear-index
                })
            }
        },

        onPageLoad:function(){
            var urlBody = this.$route.query
            if(urlBody.emp_id) {
                this.getLeaveBalanceHistory(urlBody); 
            }
        },

        convertToDate(D){
            var date = new Date(D);
            return ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth()+1)).slice(-2) + '-'+ date.getFullYear();
        },

        getDefaultFilters() {
            let api_params = {
                sort_by: this.urlBody.sort_by,
                order: this.urlBody.order,
                year: this.urlBody.year,
            };
            return api_params;
        },
        
        onFiltered() {
            this.urlBody.query = this.filter;
            let urlBody = this.deleteEmptyKeys(this.urlBody);
            this.api_error = "";
            this.$router
                .push({
                    path: this.$route.params[0],
                    query: urlBody
                }).catch(()=>{})
            this.getLeaveBalanceHistory(urlBody);
        },

        getLeaveBalanceHistory: function(params) {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = this.deleteEmptyKeys(params);
            this.axios.get(this.$api.get_employee_leave_balance_history, query).then(response => {
                this.items = response.data.data;
                this.api_error = "";
                this.updateProgressBar(true);
                this.$router.push({
                    path: this.$route.params[0],
                    query: this.deleteEmptyKeys(params)
                }).catch(()=>{})
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.items = [];
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        customSorting: function(ctx) {
            if (ctx.sortBy == "" || ctx.sortBy == null) {
                return;
            }
            let urlBody = this.deleteEmptyKeys(this.$route.query);
            urlBody.order = ctx.sortDesc ? "asc" : "desc";
            urlBody.sort_by = ctx.sortBy;
            this.api_error = "";
            this.getLeaveBalanceHistory(urlBody);
        },
        
        dateformat (date) {
             var dateFormat = new Date(date);
            var dd = String(dateFormat.getDate()).padStart(2, '0');
            var mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
            var yyyy = dateFormat.getFullYear();
            var h = dateFormat.getHours();
            var m = dateFormat.getMinutes();
            var s = dateFormat.getSeconds();
            return dd + '-' + mm + '-' + yyyy + ' ' + h + ':' + m + ':' + s;
        },
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style>

<style >
.reasonClass {
    width:15% !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 1px;

}
</style>